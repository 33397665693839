
.background1 {
 //background-color: #f7e1b5;

  background-color: #eeffcc;

}

.background2 {
  background-color: #f7ffe6;
}

.background3 {
  background-color: #e8e0f9;
}
.but-selected {

}

.app-modal-window-order .modal-dialog {
  width: 80%;
}

.text {
  max-width: 177px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }

  .input50{

    width: 50px;
    }


}
@-webkit-keyframes glowing {
  0% {  -webkit-box-shadow: 0 0 3px #e63900 }
  50% {  -webkit-box-shadow: 0 0 40px #ffd9cc; }
  100% {  -webkit-box-shadow: 0 0 3px #e63900 }
}

@-moz-keyframes glowing {
  0% {  -moz-box-shadow: 0 0 3px #e63900 }
  50% {  -moz-box-shadow: 0 0 40px #ffd9cc; }
  100% { -moz-box-shadow: 0 0 3px #e63900}
}

@-o-keyframes glowing {
  0% {  box-shadow: 0 0 3px #e63900 }
  50% {  box-shadow: 0 0 40px #ffd9cc; }
  100% {  box-shadow: 0 0 3px #e63900 }
}

@keyframes glowing {
  0% {  box-shadow: 0 0 3px #e63900 }
  50% {  box-shadow: 0 0 40px #ffd9cc; }
  100% {  box-shadow: 0 0 3px #e63900 }
}

.btn-blink {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
.btn-blink {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.color-status-processing{
  color:#8000ff;
}
.color-status-finished{
  color:#ff9900;
}
.color-status-invoiced{
  color:#cc9900;
}
.color-status-paid{
  color: #339933;
}




.greenHeaderColor{

  color: #688a7e;
}

.loadingRest{
  z-index:10; position: fixed;
  left: 50%;

  top: 50%;
}